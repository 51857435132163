import { Patient } from './Types.ts';
import { FC, useMemo } from 'react';
import { css } from '@emotion/css';
import { theme, Typography } from 'antd';
import { FemaleIcon } from '../../icons/FemaleIcon.tsx';
import { MaleIcon } from '../../icons/MaleIcon.tsx';

export const PatientInfo: FC<{
  patient: Patient;
}> = ({ patient }) => {
  const { token } = theme.useToken();
  const titleLastName = `${patient.title} ${patient.lastName}`.trim();
  const fullName = [titleLastName, patient.firstName].join(', ').trim();
  const male = patient.gender === 'M';

  const classNames = useMemo(
    () => ({
      name: css`
        line-height: 1.1em;
        margin-bottom: 0;

        @media (max-width: ${token.screenLG}px) {
          margin-top: ${token.marginMD}px;
        }
      `,
      additional: css`
        margin-bottom: ${token.margin}px;
      `,
      svnr: css`
        font-size: ${token.fontSizeSM}px;
      `,
    }),
    [token]
  );
  // TODO format date

  return (
    <>
      <h1 className={classNames.name}>{fullName}</h1>
      <div className={classNames.additional}>
        {male ? <MaleIcon /> : <FemaleIcon />} {patient.birthdate}{' '}
        <Typography.Text type="secondary" className={classNames.svnr}>
          - SVNR:{' '}
        </Typography.Text>
        <Typography.Text copyable>{patient.svnr}</Typography.Text>
      </div>
    </>
  );
};
