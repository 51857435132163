/* tslint:disable */
/* eslint-disable */
/**
 * Befunde API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { Account } from '../model';
// @ts-ignore
import type { AccountCreateOrUpdateRequest } from '../model';
// @ts-ignore
import type { AccountListResponse } from '../model';
// @ts-ignore
import type { RestError } from '../model';
/**
 * AccountApi - axios parameter creator
 * @export
 */
export const AccountApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create new account
         * @param {AccountCreateOrUpdateRequest} accountCreateOrUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount: async (accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'accountCreateOrUpdateRequest' is not null or undefined
            assertParamExists('createAccount', 'accountCreateOrUpdateRequest', accountCreateOrUpdateRequest)
            const localVarPath = `/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateOrUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary List all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccounts: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/account`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update an account
         * @param {string} id 
         * @param {AccountCreateOrUpdateRequest} accountCreateOrUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount: async (id: string, accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('updateAccount', 'id', id)
            // verify required parameter 'accountCreateOrUpdateRequest' is not null or undefined
            assertParamExists('updateAccount', 'accountCreateOrUpdateRequest', accountCreateOrUpdateRequest)
            const localVarPath = `/account/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(accountCreateOrUpdateRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AccountApi - functional programming interface
 * @export
 */
export const AccountApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = AccountApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create new account
         * @param {AccountCreateOrUpdateRequest} accountCreateOrUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createAccount(accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createAccount(accountCreateOrUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.createAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary List all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllAccounts(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AccountListResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllAccounts(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.getAllAccounts']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update an account
         * @param {string} id 
         * @param {AccountCreateOrUpdateRequest} accountCreateOrUpdateRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAccount(id: string, accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Account>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAccount(id, accountCreateOrUpdateRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['AccountApi.updateAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * AccountApi - factory interface
 * @export
 */
export const AccountApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = AccountApiFp(configuration)
    return {
        /**
         * 
         * @summary Create new account
         * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.createAccount(requestParameters.accountCreateOrUpdateRequest, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary List all accounts
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllAccounts(options?: RawAxiosRequestConfig): AxiosPromise<AccountListResponse> {
            return localVarFp.getAllAccounts(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update an account
         * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<Account> {
            return localVarFp.updateAccount(requestParameters.id, requestParameters.accountCreateOrUpdateRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for createAccount operation in AccountApi.
 * @export
 * @interface AccountApiCreateAccountRequest
 */
export interface AccountApiCreateAccountRequest {
    /**
     * 
     * @type {AccountCreateOrUpdateRequest}
     * @memberof AccountApiCreateAccount
     */
    readonly accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest
}

/**
 * Request parameters for updateAccount operation in AccountApi.
 * @export
 * @interface AccountApiUpdateAccountRequest
 */
export interface AccountApiUpdateAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof AccountApiUpdateAccount
     */
    readonly id: string

    /**
     * 
     * @type {AccountCreateOrUpdateRequest}
     * @memberof AccountApiUpdateAccount
     */
    readonly accountCreateOrUpdateRequest: AccountCreateOrUpdateRequest
}

/**
 * AccountApi - object-oriented interface
 * @export
 * @class AccountApi
 * @extends {BaseAPI}
 */
export class AccountApi extends BaseAPI {
    /**
     * 
     * @summary Create new account
     * @param {AccountApiCreateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public createAccount(requestParameters: AccountApiCreateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).createAccount(requestParameters.accountCreateOrUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary List all accounts
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public getAllAccounts(options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).getAllAccounts(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update an account
     * @param {AccountApiUpdateAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AccountApi
     */
    public updateAccount(requestParameters: AccountApiUpdateAccountRequest, options?: RawAxiosRequestConfig) {
        return AccountApiFp(this.configuration).updateAccount(requestParameters.id, requestParameters.accountCreateOrUpdateRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

