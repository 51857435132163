import { Flex, theme } from 'antd';
import React from 'react';
import { ObservationResultsRenderer } from './observationresult/ObservationResultsRenderer.tsx';
import { OrderResult } from './Types.ts';
import { RequestRenderer } from './request/RequestRenderer.tsx';
import { SpecimenRenderer } from './specimen/SpecimenRenderer.tsx';
import { useGridClassNames } from './OrderResult.styles.ts';

// TODO format date/time

export const OrderResultRenderer: React.FC<{
  result: OrderResult;
  deemphasizePathological: boolean;
}> = ({ result, deemphasizePathological }) => {
  const { token } = theme.useToken();
  const { detailsClassName } = useGridClassNames();
  // TODO WIP Filter, ....
  return (
    <Flex vertical gap={`${token.margin}px`} className={detailsClassName}>
      <RequestRenderer result={result} />
      <SpecimenRenderer result={result} />
      <ObservationResultsRenderer
        result={result}
        filter={{ pathological: null }}
        deemphasizePathological={deemphasizePathological}
      />
    </Flex>
  );
};
