import React from 'react';
import { OrderResult } from '../Types.ts';
import { useGridClassNames } from '../OrderResult.styles.ts';

export const SpecimenRenderer: React.FC<{ result: OrderResult }> = ({ result }) => {
  const { gridClassName, headerClassName, accreditationClassName, specimenClassName } = useGridClassNames();
  const specimen = result.observationSpecimen;

  if (!specimen) {
    return null;
  }

  return (
    <>
      <div className={gridClassName}>
        <div className={headerClassName}>Probenherkunft</div>
      </div>
      <div className={gridClassName}>
        <div className={accreditationClassName}>{specimen.resultDistributionCategory}</div>
        <div className={specimenClassName}>
          {specimen.testName} {specimen.value}
        </div>
      </div>
    </>
  );
};
