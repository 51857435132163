import React, { ReactNode } from 'react';
import logo from '../assets/logo.svg';
import { Alert, Badge, Button, Layout, theme } from 'antd';
import { HelpMenu } from './HelpMenu';
import { css } from '@emotion/css';
import { useAppVersionStore } from '../store/useAppVersionStore.ts';
import { Link } from 'react-router-dom';

const { useToken } = theme;
const { Header } = Layout;

const envTag: string = window._env_.ENV_TAG;

interface AppHeaderProps {
  userActions: ReactNode;
  topMenu: ReactNode;
}

export const AppHeader: React.FC<AppHeaderProps> = ({ userActions, topMenu }) => {
  const { token } = useToken();
  const { bannerVisible } = useAppVersionStore();

  const styles = {
    header: css`
      display: flex;
      position: relative;
      box-sizing: border-box;
      padding-inline: ${token.paddingLG}px !important;
    `,
    logo: css`
      img {
        width: 200px;
      }

      @media (max-width: ${token.screenLG}px) {
        img {
          width: 150px;
        }
      }
    `,
    content: css`
      flex-grow: 1;
      display: flex;
      justify-content: center;
      align-items: center;
    `,
    title: css`
      font-weight: 500;
      font-size: 24px;
      @media (max-width: ${token.screenLG}px) {
        display: none;
      }
    `,
    versionAlert: css`
      background-color: ${token.colorError};
      border: none;
      color: white;

      @media (max-width: ${token.screenLG}px) {
        display: none;
      }

      .ant-btn {
        margin-left: ${token.marginSM}px;
        animation: pulsate 3s ease-out;
        animation-iteration-count: infinite;
        opacity: 1;

        @keyframes pulsate {
          0% {
            opacity: 1;
          }
          50% {
            opacity: 0.5;
          }
          100% {
            opacity: 1;
          }
        }
      }
    `,
    actions: css`
      line-height: initial;
      display: flex;
      align-items: center;
      gap: ${token.paddingLG}px;

      @media (max-width: ${token.screenLG}px) {
        gap: ${token.paddingSM}px;
      }
    `,
  };

  return (
    <Header className={styles.header}>
      <Link to="/" className={styles.logo}>
        {envTag && envTag !== 'prod' ? (
          <Badge.Ribbon text={envTag} color="volcano">
            <img src={logo} alt="labcomplete Befunde" />
          </Badge.Ribbon>
        ) : (
          <img src={logo} alt="labcomplete Befunde" />
        )}
      </Link>
      <div className={styles.content}>
        {bannerVisible() ? (
          <Alert
            message={<strong>Neue Version verfügbar!</strong>}
            type="error"
            action={<Button onClick={() => window.location.reload()}>Jetzt aktualisieren</Button>}
            className={styles.versionAlert}
          />
        ) : (
          <span className={styles.title}>Befunde</span>
        )}
      </div>
      <div className={styles.actions}>
        {topMenu}
        {userActions}
        <HelpMenu />
      </div>
    </Header>
  );
};
