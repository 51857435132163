import { FC } from 'react';
import { Popover } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { css } from '@emotion/css';
import { HelpSupport } from './HelpSupport.tsx';

export const HelpMenu: FC = () => {
  return (
    <Popover content={<HelpSupport />} placement="bottomRight" align={{ offset: [10, 15] }} arrow trigger={['click']}>
      <QuestionCircleOutlined
        className={css`
          font-size: 24px;
          cursor: pointer;
        `}
      />
    </Popover>
  );
};
