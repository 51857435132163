import { FC } from 'react';
import { Button, Dropdown, Grid, MenuProps } from 'antd';
import { SettingOutlined, TeamOutlined, UserOutlined } from '@ant-design/icons';
import { Link, useLocation } from 'react-router-dom';
import { useAuth } from 'react-oidc-context';
import { hasSomeRole, Role } from '../utils/user.ts';

const items: MenuProps['items'] = [
  {
    key: '/admin/accounts',
    label: <Link to="/admin/accounts">Accounts</Link>,
    icon: <TeamOutlined />,
  },
  {
    key: '/admin/benutzer',
    label: <Link to="/admin/benutzer">Benutzer</Link>,
    icon: <UserOutlined />,
  },
];

export const TopMenu: FC = () => {
  const location = useLocation();
  const auth = useAuth();
  const screens = Grid.useBreakpoint();

  if (!hasSomeRole([Role.ROLE_BEF_MEDCOM], auth.user)) {
    return null;
  }

  return (
    <Dropdown menu={{ items: items, selectable: true, selectedKeys: [location.pathname] }}>
      <Button icon={<SettingOutlined />} onClick={e => e.preventDefault()}>
        {screens.lg || screens.xl || screens.xxl ? 'Admin' : null}
      </Button>
    </Dropdown>
  );
};
