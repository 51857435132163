import React from 'react';
import { Button, Card, Checkbox, Dropdown, Form, FormInstance, Input, Space, Typography } from 'antd';
import { Account, AccountAuthz, LabId } from '../../../generated/openapi/befunde-api';
import { EditableTagGroup } from '../../../components/EditableTagGroup.tsx';
import { DeleteOutlined, PlusCircleOutlined } from '@ant-design/icons';
import { useWatch } from 'antd/es/form/Form';
import { css } from '@emotion/css';

export type FormModel = Omit<Account, 'id'>;

export const AccountForm: React.FC<{
  form: FormInstance<FormModel>;
  initialValues?: FormModel;
  onFinish: (values: FormModel) => void;
}> = ({ form, initialValues, onFinish }) => {
  const authz = useWatch<AccountAuthz[]>('authz', form);

  return (
    <Form<FormModel> layout="vertical" form={form} onFinish={onFinish} initialValues={initialValues}>
      <Form.Item name="name" label="Name" rules={[{ type: 'string', required: true, whitespace: false }]}>
        <Input autoFocus />
      </Form.Item>
      <Form.Item name="disabled" valuePropName="checked">
        <Checkbox>Deaktiviert</Checkbox>
      </Form.Item>
      <Form.List
        name="authz"
        rules={[
          {
            validator: (_, values) => {
              if (!values || values.length < 1) {
                return Promise.reject('Mind. ein Labor angeben');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        {(fields, { add, remove }, { errors }) => (
          <Space direction="vertical" style={{ width: '100%' }}>
            <Dropdown
              menu={{
                items: Object.values(LabId).map(lab => ({
                  key: lab,
                  label: lab,
                  disabled: authz?.map(it => it.labId as string)?.includes(lab) ?? false,
                  onClick: () =>
                    add({
                      labId: lab,
                      identifiers: [],
                    }),
                })),
              }}
              placement="bottomLeft"
              trigger={['click']}
            >
              <Button icon={<PlusCircleOutlined />}>Labor hinzufügen</Button>
            </Dropdown>
            {fields.map(({ key, name, ...restField }, index) => (
              <Card
                size="small"
                title={Object.values(LabId).find(it => it === (authz ?? [])[index]?.labId) ?? `Labor ${index + 1}`}
                extra={
                  <Button danger size="small" icon={<DeleteOutlined />} type="text" onClick={() => remove(index)} />
                }
                style={{ width: '100%' }}
                key={key}
              >
                <Form.Item
                  {...restField}
                  name={[name, 'identifiers']}
                  label="Identifikatoren"
                  valuePropName="tags"
                  rules={[{ type: 'array' }]}
                  className={css`
                    margin-bottom: 0;
                  `}
                >
                  <EditableTagGroup addLabel="Identifikator hinzufügen" inputWidth="170px" />
                </Form.Item>
              </Card>
            ))}
            <Form.ErrorList
              errors={errors.map((it, idx) => (
                <Typography.Text key={idx} type="danger">
                  {it}
                </Typography.Text>
              ))}
            />
          </Space>
        )}
      </Form.List>
      {/* hack for enter button support */}
      <Button htmlType="submit" hidden />
    </Form>
  );
};
