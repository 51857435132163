import { useQuery } from '@tanstack/react-query';
import { AxiosError } from 'axios';
import { Configuration, UserInfo, UserInfoApi } from '../generated/openapi/befunde-api';
import { loadUser } from '../oidcConfig.ts';

const api = new UserInfoApi(
  new Configuration({ accessToken: () => loadUser()?.access_token ?? '' }),
  window._env_.API_URL
);

export const useGetUserInfo = () =>
  useQuery<UserInfo, AxiosError>({
    queryKey: [api.getUserInfo.name],
    queryFn: async () => {
      const res = await api.getUserInfo();
      return res?.data;
    },
    retry: false,
  });
