import { css } from '@emotion/css';
import { theme } from 'antd';
import { useMemo } from 'react';

const accreditationWidth = '20px';
const pathologicalWidth = '32px';
const unitWidth = '120px';
const rangeWidth = '130px';
const graphicWidth = '200px';
const firstBreak = '900px';
const secondBreak = '700px';

export const useGridClassNames = () => {
  const { token } = theme.useToken();

  return useMemo(() => {
    const emphasizedClassName = css`
      color: ${token.colorError};
      font-weight: bold;
    `;

    const detailsClassName = css`
      container-name: observation-result-row;
      container-type: inline-size;
    `;
    const headerBorderClassName = css`
      padding-bottom: ${token.padding}px;
      border-bottom: 1px solid ${token.colorBorder};
    `;
    const entryBorderClassName = css`
      padding-bottom: ${token.padding}px;
      border-bottom: 1px dashed ${token.colorBorder};
    `;
    const headerClassName = css`
      grid-column: 1 / span 6;
      font-size: ${token.fontSizeHeading3}px;
    `;
    const subheaderClassName = css`
      color: ${token.colorTextDisabled};
      font-size: ${token.fontSizeSM}px;
      font-weight: bold;
      text-transform: uppercase;
    `;
    const accreditationClassName = css`
      grid-area: accreditation;
      width: 100%;
    `;
    const specimenClassName = css`
      grid-column: 2 / span 5;
    `;
    const parameterClassName = css`
      grid-area: parameter;
      width: auto;
      display: flex;
      overflow: hidden;
      & > span:first-child {
        flex-grow: 1;
        padding-right: ${token.padding}px;
      }
    `;
    const pathologicalClassName = css`
      grid-area: pathological;
    `;
    const unitClassName = css`
      grid-area: unit;
      text-align: center;
      @container observation-result-row (width < ${secondBreak}) {
        & {
          text-align: right;
        }
      }
    `;
    const rangeClassName = css`
      grid-area: range;
      text-align: center;
      @container observation-result-row (width < ${secondBreak}) {
        & {
          text-align: right;
        }
      }
    `;
    const graphicClassName = css`
      grid-area: graphic;

      @container observation-result-row (width < ${firstBreak}) {
        & {
          display: none;
        }
      }
    `;
    const commentClassName = css`
      grid-area: graphic;
      grid-column: 2 / span 5;
      position: relative;
      overflow-x: auto;

      & span {
        position: absolute;
        top: 2px;
        color: ${token.colorInfo};
      }

      & pre {
        color: ${token.colorInfo};
        margin-left: 24px;
        font-size: 12px;
      }
    `;
    const gridClassName = css`
      display: grid;
      grid-template-columns: ${accreditationWidth} auto ${pathologicalWidth} ${unitWidth} ${rangeWidth} ${graphicWidth};
      grid-template-areas: 'accreditation parameter pathological unit range graphic';
      gap: ${token.padding}px;
      width: 100%;

      @container observation-result-row (width < ${firstBreak}) {
        grid-template-columns: ${accreditationWidth} auto ${pathologicalWidth} ${unitWidth} ${rangeWidth};
        grid-template-areas: 'accreditation parameter pathological unit range';
      }
      @container observation-result-row (width < ${secondBreak}) {
        & {
          grid-template-columns: ${accreditationWidth} auto ${pathologicalWidth};
          grid-template-areas:
            'accreditation parameter pathological'
            '. unit .'
            '. range .';
        }
      }
    `;
    return {
      detailsClassName,
      headerBorderClassName,
      entryBorderClassName,
      emphasizedClassName,
      gridClassName,
      headerClassName,
      subheaderClassName,
      accreditationClassName,
      specimenClassName,
      parameterClassName,
      pathologicalClassName,
      unitClassName,
      rangeClassName,
      graphicClassName,
      commentClassName,
    };
  }, [token]);
};
