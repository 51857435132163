import React from 'react';
import { OrderResult } from '../Types.ts';

export const RequestRenderer: React.FC<{ result: OrderResult }> = ({ result }) => {
  // TODO WIP
  return (
    <pre>
      Auftragnummer: {result.orderNumber}
      <br />
      Eingangdatum: {result.observationRequest.orderEntryDate}
      <br />
      Status: {result.observationRequest.labResultStatus}
    </pre>
  );
};
