import React from 'react';
import { App, Button, Modal } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { AccountForm, FormModel } from './AccountForm.tsx';
import { useCreateAccount } from '../../../api/account.queries.ts';

export const CreateAccountModal: React.FC<{
  open: boolean;
  onClose: () => void;
}> = ({ open, onClose }) => {
  const [form] = useForm<FormModel>();
  const { message } = App.useApp();
  const { mutate, isPending } = useCreateAccount();

  const create = async (values: FormModel) => {
    mutate(
      {
        accountCreateOrUpdateRequest: values,
      },
      {
        onSuccess: () => {
          message.success('Account wurde erstellt');
          closeAndReset();
        },
        onError: e => {
          message.error('Account konnte nicht erstellt werden: ' + e);
        },
      }
    );
  };

  const closeAndReset = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      width={600}
      title="Neuen Account erstellen"
      open={open}
      destroyOnClose
      onCancel={closeAndReset}
      footer={[
        <Button key="cancel" onClick={closeAndReset}>
          Abbrechen
        </Button>,
        <Button key="submit" icon={<PlusCircleOutlined />} type="primary" loading={isPending} onClick={form.submit}>
          Erstellen
        </Button>,
      ]}
    >
      <AccountForm
        form={form}
        initialValues={{
          name: '',
          disabled: false,
          authz: [],
        }}
        onFinish={create}
      />
    </Modal>
  );
};
