import React, { useEffect, useState } from 'react';
import { UserMenu } from './components/UserMenu';
import { realmRoles, Role, translateRole, userFullname } from './utils/user';
import { useAuth } from 'react-oidc-context';
import { oidcConfig } from './oidcConfig.ts';
import { Md5 } from 'ts-md5';
import { useSocket } from './socket/useSocket.ts';
import { useGetUserInfo } from './api/user-info.queries.ts';

export const UserMenuWrapper: React.FC = () => {
  const [pictureUrl, setPrictureUrl] = useState<string>('');

  const auth = useAuth();
  const { socket } = useSocket();
  const { data } = useGetUserInfo();

  const roleNames =
    realmRoles(auth.user)
      .filter(it => Object.keys(Role).includes(it))
      .map(it => translateRole(it))
      .join(', ') ?? '';

  useEffect(() => {
    if (auth.user?.profile.email) {
      const emailHash = Md5.hashStr(auth.user.profile.email);
      setPrictureUrl(`https://s.gravatar.com/avatar/${emailHash}?d=mp&r=pg&s=64`);
    }
  }, [auth]);

  useEffect(() => {
    socket.on('sso-logout', event => {
      if (auth.user?.session_state === event.sessionId) {
        auth.signoutRedirect(); // redirects to kc logout page
      }
    });
    return () => {
      socket.off('sso-logout');
    };
  }, [socket, auth]);

  const userFullName = userFullname(auth.user, auth.user?.profile.preferred_username);
  const accountName = data?.accountName ? data.accountName : 'Kein Account festgelegt';

  return (
    <UserMenu
      roleName={roleNames}
      username={auth.user?.profile.preferred_username}
      pictureUrl={pictureUrl}
      accountName={accountName}
      accountDisabled={data?.accountDisabled ?? false}
      userFullName={userFullName}
      onUserAccountOpen={() =>
        (window.location.href =
          oidcConfig.authority +
          `/account?referrer=${oidcConfig.client_id}&referrer_uri=${encodeURIComponent(window.location.href)}`)
      }
      onLogout={() => {
        const sessionId = auth.user?.session_state;
        if (sessionId) {
          socket.emit('sso-logout', sessionId);
        }
      }}
    />
  );
};
