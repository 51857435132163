import React from 'react';
import { Flex, theme } from 'antd';
import { css } from '@emotion/css';
import { BefundeList } from './befunde/BefundeList.tsx';
import { BefundDetails } from './befunde/BefundDetails.tsx';
import { useParams } from 'react-router';

export const Befunde: React.FC = () => {
  const { token } = theme.useToken();
  const { id } = useParams();

  return (
    <Flex
      className={css`
        height: 100%;
        max-width: 100vw;
        overflow: hidden;
        padding: ${token.paddingLG}px;
        position: relative; /* important for absolute details list on mobile */

        @media (max-width: ${token.screenLG}px) {
          padding-top: ${token.paddingXS}px;
        }
      `}
    >
      <BefundeList selectedId={id} />
      <BefundDetails selectedId={id} deemphasizePathological={false} />
    </Flex>
  );
};
