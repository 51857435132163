import { OrderResult } from '../orderresult/Types.ts';

export const sample: OrderResult = {
  orderNumber: '9800963',
  patient: {
    svnr: '2194201175',
    lastName: 'ZEMAN',
    firstName: 'Christian',
    title: '',
    birthdate: '1975-11-20',
    gender: 'W',
  },
  patientUnlock: true,
  receiver: {
    salutation: 'Frau',
    tile: 'Dr.',
    firstName: 'Reneé',
    lastName: 'TAUFFER',
    zip: '1050',
    city: 'Wien',
    webResult: false,
  },
  observationRequest: {
    orderEntryDate: '2023-07-18T12:37:00',
    labResultStatus: 'END_RESULT',
  },
  observationSpecimen: {
    testName: 'überbrachte Probe vom',
    value: '18.07.23',
    resultDistributionCategory: 'N',
  },
  observationResults: [
    {
      testName: 'Leukozyten',
      resultGroup: 'Lymphozytentypisierung (aus EDTA-Blut)',
      accreditation: 'A',
      measuredValue: '17700',
      unit: 'Zellen/ul',
      standardRange: '4000 - 10000',
      rangeFrom: '4000',
      rangeTo: '10000',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Lymphozyten',
      resultGroup: 'Lymphozytentypisierung (aus EDTA-Blut)',
      accreditation: 'A',
      measuredValue: '16',
      unit: '%',
      standardRange: '(20 - 45)',
      rangeFrom: '20',
      rangeTo: '45',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Lymphozyten, absolut',
      resultGroup: 'Lymphozytentypisierung (aus EDTA-Blut)',
      accreditation: 'A',
      measuredValue: '2832',
      unit: 'Zellen/ul',
      standardRange: '(1000 - 4800)',
      rangeFrom: '1000',
      rangeTo: '4800',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Lymphozytentypisierung',
      resultGroup: 'Lymphozytentypisierung (aus EDTA-Blut)',
      accreditation: 'A',
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Leukozytose. Relative Lymphopenie.',
        'Erhöhte CD4/CD8 Ratio.',
        'Diff. Dg.: Frühphase einer adaptiven Immunreaktion,',
        'z.B. bei Infektion, ev. Autoimmunerkrankungen.',
        'Verlaufskontrolle empfohlen!',
        '**************************************************',
      ],
    },
    {
      testName: 'T-Zellen gesamt CD3',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '71',
      unit: '%',
      standardRange: '(60 - 85)',
      rangeFrom: '60',
      rangeTo: '85',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Zellen gesamt CD3',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '2011',
      unit: 'Zellen/ul',
      standardRange: '(680 - 2750)',
      rangeFrom: '680',
      rangeTo: '2750',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Helferzellen CD4',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '54',
      unit: '%',
      standardRange: '(33 - 54)',
      rangeFrom: '33',
      rangeTo: '54',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Helferzellen CD4',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '1529',
      unit: 'Zellen/ul',
      standardRange: '(500 - 1500)',
      rangeFrom: '500',
      rangeTo: '1500',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytotox. CD8',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '17',
      unit: '%',
      standardRange: '(28 - 37)',
      rangeFrom: '28',
      rangeTo: '37',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytotox. CD8',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '481',
      unit: 'Zellen/ul',
      standardRange: '(250 - 1000)',
      rangeFrom: '250',
      rangeTo: '1000',
      pathological: null,
      comment: [],
    },
    {
      testName: 'CD4:CD8 Ratio',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '3.18',
      unit: 'Ratio',
      standardRange: '(0.65 - 2.50)',
      rangeFrom: '0.65',
      rangeTo: '2.50',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytot.akt.CD8/HLA-DR',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '8',
      unit: '%',
      standardRange: '(2 - 12)',
      rangeFrom: '2',
      rangeTo: '12',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytot.akt.CD8/HLA-DR',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '227',
      unit: 'Zellen/ul',
      standardRange: '(60 - 300)',
      rangeFrom: '60',
      rangeTo: '300',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytotox.akt.CD3/8/38',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '5',
      unit: '%',
      standardRange: '(5 - 16)',
      rangeFrom: '5',
      rangeTo: '16',
      pathological: null,
      comment: [],
    },
    {
      testName: 'T-Suppr.-Cytotox.akt.CD3/8/38',
      resultGroup: 'T-Lymphozytensubpopulationen',
      accreditation: 'A',
      measuredValue: '142',
      unit: 'Zellen/ul',
      standardRange: '(60 - 350)',
      rangeFrom: '60',
      rangeTo: '350',
      pathological: null,
      comment: [],
    },
    {
      testName: 'NK T-Zellen CD3+CD16+CD56+',
      resultGroup: 'Natural Killer Zellen',
      accreditation: 'A',
      measuredValue: '4',
      unit: '%',
      standardRange: '(1 - 12)',
      rangeFrom: '1',
      rangeTo: '12',
      pathological: null,
      comment: [],
    },
    {
      testName: 'NK T-Zellen CD3+CD16+CD56+',
      resultGroup: 'Natural Killer Zellen',
      accreditation: 'A',
      measuredValue: '113',
      unit: 'Zellen/ul',
      standardRange: '(20 - 220)',
      rangeFrom: '20',
      rangeTo: '220',
      pathological: null,
      comment: [],
    },
    {
      testName: 'NK-Zellen CD3-CD16+CD56+',
      resultGroup: 'Natural Killer Zellen',
      accreditation: 'A',
      measuredValue: '15',
      unit: '%',
      standardRange: '(8 - 20)',
      rangeFrom: '8',
      rangeTo: '20',
      pathological: null,
      comment: [],
    },
    {
      testName: 'NK-Zellen CD3-CD16+CD56+',
      resultGroup: 'Natural Killer Zellen',
      accreditation: 'A',
      measuredValue: '425',
      unit: 'Zellen/ul',
      standardRange: '(90 - 600)',
      rangeFrom: '90',
      rangeTo: '600',
      pathological: null,
      comment: [],
    },
    {
      testName: 'B-Zellen gesamt CD19',
      resultGroup: 'B-Lymphozyten',
      accreditation: 'A',
      measuredValue: '10',
      unit: '%',
      standardRange: '(7 - 23)',
      rangeFrom: '7',
      rangeTo: '23',
      pathological: null,
      comment: [],
    },
    {
      testName: 'B-Zellen gesamt CD19',
      resultGroup: 'B-Lymphozyten',
      accreditation: 'A',
      measuredValue: '283',
      unit: 'Zellen/ul',
      standardRange: '(71 - 600)',
      rangeFrom: '71',
      rangeTo: '600',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Erythrozyten',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '5.00',
      unit: 'T/l',
      standardRange: '3.8 - 5.2',
      rangeFrom: '3.8',
      rangeTo: '5.2',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Hämoglobin',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '5.0',
      unit: 'g/dl',
      standardRange: '12.0 - 16.0',
      rangeFrom: '12.0',
      rangeTo: '16.0',
      pathological: '!*',
      comment: [],
    },
    {
      testName: 'Hämatokrit',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '53',
      unit: '%',
      standardRange: '35 - 47',
      rangeFrom: '35',
      rangeTo: '47',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'MCV',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '61',
      unit: 'fl',
      standardRange: '78 - 98',
      rangeFrom: '78',
      rangeTo: '98',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'MCH',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '30.0',
      unit: 'pg',
      standardRange: '27.0 - 33.0',
      rangeFrom: '27.0',
      rangeTo: '33.0',
      pathological: null,
      comment: [],
    },
    {
      testName: 'MCHC',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '30.0',
      unit: 'g/dl',
      standardRange: '31.5 - 36.0',
      rangeFrom: '31.5',
      rangeTo: '36.0',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Beurteilung rotes Blutbild:',
      resultGroup: 'Rotes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '.',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: ['Anämie', 'Mikrozytose'],
    },
    {
      testName: 'Leukozyten',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '20.0',
      unit: 'G/l',
      standardRange: '4.0 - 10.0',
      rangeFrom: '4.0',
      rangeTo: '10.0',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Diff. Blutbild',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'relativ',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Diff.BB abs.',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'absolut',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Segmentkernige',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '21.0',
      unit: '%',
      standardRange: '(50-75)',
      rangeFrom: '50',
      rangeTo: '75',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Segmentkernige, absolut',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '2.00',
      unit: 'G/l',
      standardRange: '(2.0-7.5)',
      rangeFrom: '2.0',
      rangeTo: '7.5',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Eosinophile',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '2.0',
      unit: '%',
      standardRange: '(< 4)',
      rangeFrom: null,
      rangeTo: '4',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Eosinophile, absolut',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '0.10',
      unit: 'G/l',
      standardRange: '(< 0.4)',
      rangeFrom: null,
      rangeTo: '0.4',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Basophile',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '3.0',
      unit: '%',
      standardRange: '(0-2)',
      rangeFrom: '0',
      rangeTo: '2',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Basophile, absolut',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '0.10',
      unit: 'G/l',
      standardRange: '(< 0.2)',
      rangeFrom: null,
      rangeTo: '0.2',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Monozyten',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '4.0',
      unit: '%',
      standardRange: '(< 12)',
      rangeFrom: null,
      rangeTo: '12',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Monozyten, absolut',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '0.20',
      unit: 'G/l',
      standardRange: '(< 1.2)',
      rangeFrom: null,
      rangeTo: '1.2',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Lymphozyten',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '5.0',
      unit: '%',
      standardRange: '(20-40)',
      rangeFrom: '20',
      rangeTo: '40',
      pathological: '!*',
      comment: [],
    },
    {
      testName: 'Lymphozyten, absolut',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '0.50',
      unit: 'G/l',
      standardRange: '(1.0-4.0)',
      rangeFrom: '1.0',
      rangeTo: '4.0',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Beurteilung weißes Blutbild:',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '.',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Leukozytose',
        'relative Neutropenie',
        'relative Basophilie',
        'relative Lymphopenie',
        'absolute Lymphopenie',
      ],
    },
    {
      testName: 'Thrombozyten',
      resultGroup: 'Weißes Blutbild (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: '599',
      unit: 'G/l',
      standardRange: '150 - 350',
      rangeFrom: '150',
      rangeTo: '350',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Gesamteiweiß',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '6.3',
      unit: 'g/dl',
      standardRange: '6.6 - 8.3',
      rangeFrom: '6.6',
      rangeTo: '8.3',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Albumin',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '66.0',
      unit: '%',
      standardRange: '(55.8 - 66.1)',
      rangeFrom: '55.8',
      rangeTo: '66.1',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Albumin abs.',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '4.2',
      unit: 'g/dl',
      standardRange: '(3.35 - 5.29)',
      rangeFrom: '3.35',
      rangeTo: '5.29',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Alpha-1-Globulin',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '4.0',
      unit: '%',
      standardRange: '(2.9 - 4.9)',
      rangeFrom: '2.9',
      rangeTo: '4.9',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Alpha-1-Glob. abs',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '0.3',
      unit: 'g/dl',
      standardRange: '(0.16 - 0.38)',
      rangeFrom: '0.16',
      rangeTo: '0.38',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Alpha-2-Globulin',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '4.0',
      unit: '%',
      standardRange: '(7.1 - 11.8)',
      rangeFrom: '7.1',
      rangeTo: '11.8',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Alpha-2-Glob. abs',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '0.3',
      unit: 'g/dl',
      standardRange: '(0.43 - 0.94)',
      rangeFrom: '0.43',
      rangeTo: '0.94',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Beta-Globulin',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '6.0',
      unit: '%',
      standardRange: '(8.4 - 13.1)',
      rangeFrom: '8.4',
      rangeTo: '13.1',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Beta-Glob. abs.',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '0.4',
      unit: 'g/dl',
      standardRange: '(0.52 - 1.05)',
      rangeFrom: '0.52',
      rangeTo: '1.05',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Gamma-Globulin',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '20.0',
      unit: '%',
      standardRange: '(11.1 - 18.8)',
      rangeFrom: '11.1',
      rangeTo: '18.8',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Gamma-Glob. abs.',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: null,
      measuredValue: '1.3',
      unit: 'g/dl',
      standardRange: '(0.67 - 1.51)',
      rangeFrom: '0.67',
      rangeTo: '1.51',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Selen',
      resultGroup: 'Klinische Chemie (aus Serum)',
      accreditation: 'A',
      measuredValue: '100',
      unit: 'µg/l',
      standardRange: '50 - 120',
      rangeFrom: '50',
      rangeTo: '120',
      pathological: null,
      comment: [
        '< 25 µg/l     : Selenmangel            > 120 µg/l  : unter Therapie',
        '25-50 µg/l    : suboptimal             > 400 µg/l  : toxisch',
        '50 - 120 µg/l : physiologisch',
      ],
    },
    {
      testName: 'Blutgruppe',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'AB',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Rhesusfaktor',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'POSITIV',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Irr. Erythrozytenantikörper',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'POSITIV',
      unit: null,
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Antikörperidentifizierung',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'Anti-Jk(a)',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Antigen für AK-Identifizierung',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: 'N',
      measuredValue: 'Jk(a) negativ',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'direkter Coombstest',
      resultGroup: 'Blutgruppenserologie (aus EDTA-Blut)',
      accreditation: null,
      measuredValue: 'POSITIV',
      unit: null,
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Östradiol (E2)',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '20.0',
      unit: 'pg/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Follikelphase: 24 - 251     Postmenopause ohne Hormontherapie: - 28',
        'Zyklusmitte:   38 - 649     Postmenopause mit Hormontherapie:  - 144',
        'Lutealphase:   24 - 312',
      ],
    },
    {
      testName: 'FSH',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '21.0',
      unit: 'U/l',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Follikel Phase:  3.0  - 8.08             Schwanger:      < 0.3',
        'Mittzyklus:      2.55 - 16.70            Postmenopausal: 25.8 - 133.4',
        'Luteale Phase:   1.38 - 5.47',
      ],
    },
    {
      testName: 'LH/FSH Quotient',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '0.476',
      unit: 'Ratio',
      standardRange: '< 2.0',
      rangeFrom: null,
      rangeTo: '2.0',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Prolaktin',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '20.00',
      unit: 'ng/ml',
      standardRange: '5.18 - 26.53',
      rangeFrom: '5.18',
      rangeTo: '26.53',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Testosteron',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '1.00',
      unit: 'ng/ml',
      standardRange: '0.11 - 0.57',
      rangeFrom: '0.11',
      rangeTo: '0.57',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Progesteron',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '20.0',
      unit: 'ng/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Follikelphase: < 0.1 - 0.3              Schwangerschaft:',
        'Lutealphase:   1.2   - 15.9               1. Trimenon: 2.8  - 147.3',
        '2. Trimenon: 22.5 - 95.3',
        'Postmenopause: <0.1 - 0.2                 3. Trimenon: 27.9 - 242.5',
      ],
    },
    {
      testName: 'LH',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '10.00',
      unit: 'mIU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Follikelphase:    1.80 - 11.78         Postmenopause:  5.16 - 61.99',
        'Mittzyklus:       7.59 - 89.08         (ohne Hormontherapie)',
        'Lutealphase:      0.56 - 14.00',
      ],
    },
    {
      testName: 'Beta-HCG',
      resultGroup: 'Hormonbefunde (aus Serum)',
      accreditation: null,
      measuredValue: '100000',
      unit: 'U/l',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: '!*',
      comment: [
        'In Schwangerschaft erwartete Werte:',
        '',
        'Wochen seit letzter Menstruation',
        '1 - 10    202 - 231000',
        '11- 15  22500 - 235000',
        '16- 22   8000 -  50000',
        '23- 40   1600 -  49000',
        '',
        'postmenopausal: < 5.0',
      ],
    },
    {
      testName: 'CRP quantitativ',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '12.0',
      unit: 'mg/dl',
      standardRange: '< 0.5',
      rangeFrom: null,
      rangeTo: '0.5',
      pathological: '!*',
      comment: [],
    },
    {
      testName: 'Rötel-IgG-Antikörper',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: 'negativ',
      unit: 'Titer',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Es wird erst ab einem Titer von 1:16 von einer ausreichenden Immunität',
        'ausgegangen. Bei einem Titer unter 1:16 wird zur Impfung geraten.',
      ],
    },
    {
      testName: 'Rötel-Antikörper Extinktion',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '0',
      unit: 'U/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '0.0 - 10 U/ml   negativ              80.1 - 140 U/ml   1:64',
        '10.1 - 30 U/ml   1:16                140.1 - 240 U/ml   1:128',
        '30.1 - 80 U/ml   1:32',
      ],
    },
    {
      testName: 'IgA',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '200',
      unit: 'mg/dl',
      standardRange: '70 - 400',
      rangeFrom: '70',
      rangeTo: '400',
      pathological: null,
      comment: [],
    },
    {
      testName: 'IgM',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '190',
      unit: 'mg/dl',
      standardRange: '40 - 230',
      rangeFrom: '40',
      rangeTo: '230',
      pathological: null,
      comment: [],
    },
    {
      testName: 'IgG',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '500',
      unit: 'mg/dl',
      standardRange: '700 - 1600',
      rangeFrom: '700',
      rangeTo: '1600',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'Immunfixation',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: 'N',
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '*********************************************************',
        'nicht mit Sicherheit ausschließbar: Monoklonale IgG-Gammopathie,',
        'Kappa-Leichtkettentyp:',
        'IgG/Kappa-Paraprotein.',
        'DD: monoklonale Gammopathie unklarer Signifikanz (MGUS),',
        'evtl. Begleitgammopathie bei B-NHL.',
        'Verlaufskontrolle in 3-6 Monaten empfehlenswert.',
        '*********************************************************',
      ],
    },
    {
      testName: 'Tetanus Antikörper',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: 'N',
      measuredValue: '20.00',
      unit: 'IU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: ['Richtwerte für die Impfpraxis:', '> 0.1: Impfschutz für 1 Jahr', '> 5.0: langfristiger Impfschutz'],
    },
    {
      testName: 'Diphtherie Antikörper',
      resultGroup: 'Serologische Befunde (aus Serum)',
      accreditation: 'N',
      measuredValue: '40.00',
      unit: 'IU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Richtwerte für die Impfpraxis:',
        '< 0.1   IU/ml Kein Impfschutz',
        '0.1-1.0 IU/ml Immunität ausreichend, Auffrischung empfohlen.',
        '> 1.0   IU/ml Sichere Langzeitimmunität, Ko in 5 Jahren',
      ],
    },
    {
      testName: 'Material:',
      resultGroup: 'Kultur-Befund',
      accreditation: 'N',
      measuredValue: 'Nagel',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Abnahmedatum:',
      resultGroup: 'Kultur-Befund',
      accreditation: 'N',
      measuredValue: '17.07.23',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Abnahmestelle:',
      resultGroup: 'Kultur-Befund',
      accreditation: 'N',
      measuredValue: 'Finger links',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Kultur:',
      resultGroup: 'Kultur-Befund',
      accreditation: 'A',
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '1) Escherichia coli mäßig',
        '2) Corynebacterium singulare mäßig',
        '3) Streptococcus oralis reichlich',
        'Ad 1) Mitglieder der Darmflora, Kolonisation möglich.',
        'Ad 2) Kolonisation möglich.',
        'Ad 3) Kolonisation möglich.',
      ],
    },
    {
      testName: 'Antibiogramm',
      resultGroup: 'Kultur-Befund',
      accreditation: 'A',
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Enterobakterien',
        '.                               1',
        '.Amox. u. Clav.                 +',
        '.Cefotaxim                      +',
        '.Ceftazidim                     +',
        '.Ciprofloxacin                  +',
        '.Meropenem                      +',
        '.Cefepim                        +',
        '.Ampicillin                     -',
        '.',
        'Antimikrobielle Empfindlichkeitsprüfung folgt EUCAST Richtlinien',
        'in der jeweils gültigen Fassung.',
        '+, empfindlich; X, Dosis-abhängig empfindlich; -, resistent;',
        'n, nicht indiziert. Dosierungsempfehlungen siehe:',
        'https://www.eucast.org/clinical_breakpoints/',
      ],
    },
    {
      testName: 'Gramfärbung:',
      resultGroup: 'Kultur-Befund',
      accreditation: 'A',
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: ['Detritus u. Fibrin   Gram-pos. Kokken: +++   Gram-pos. Stäbchen: ++', 'Gram-neg. Stäbchen: +++'],
    },
    {
      testName: 'spezifisches Gewicht',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '1012',
      unit: null,
      standardRange: '1005 - 1030',
      rangeFrom: '1005',
      rangeTo: '1030',
      pathological: null,
      comment: [],
    },
    {
      testName: 'PH-Wert',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '5.9',
      unit: null,
      standardRange: '4.5 - 8.0',
      rangeFrom: '4.5',
      rangeTo: '8.0',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Nitrit',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '20',
      unit: null,
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Eiweiß',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: 'negativ',
      unit: 'mg/dl',
      standardRange: '< 25',
      rangeFrom: null,
      rangeTo: '25',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Glucose',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '50',
      unit: 'mg/dl',
      standardRange: '< 30',
      rangeFrom: null,
      rangeTo: '30',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Ketonkörper',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: 'negativ',
      unit: 'mg/dl',
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Bilirubin',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: 'POSITIV',
      unit: 'mg/dl',
      standardRange: '< 0.5',
      rangeFrom: null,
      rangeTo: '0.5',
      pathological: null,
      comment: [],
    },
    {
      testName: 'Urobilinogen',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '30',
      unit: 'mg/dl',
      standardRange: '< 2',
      rangeFrom: null,
      rangeTo: '2',
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Leukozyten',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '20',
      unit: 'Leuco/mcl',
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Erythrozyten',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '250',
      unit: 'Ery/mcl',
      standardRange: 'negativ',
      rangeFrom: 'negativ',
      rangeTo: null,
      pathological: '*+',
      comment: [],
    },
    {
      testName: 'Sediment:',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: '.',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Erythrozyten',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: 'reichlich',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Bakterien',
      resultGroup: 'Harnbefunde',
      accreditation: null,
      measuredValue: 'mäßig',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Hepatitis B - Antikörper (IgG)',
      resultGroup: 'Hepatitisserologie (aus Serum)',
      accreditation: null,
      measuredValue: '50',
      unit: 'mU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Impfempfehlung:',
      resultGroup: 'Hepatitisserologie (aus Serum)',
      accreditation: null,
      measuredValue: '.',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: ['Hepatitis-B-Auffrischungsimpfung innerhalb eines Jahres empfohlen.'],
    },
    {
      testName: 'Hepatitis A - Antikörper (IgG)',
      resultGroup: 'Hepatitisserologie (aus Serum)',
      accreditation: null,
      measuredValue: '15.0',
      unit: 'mU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Es gelten folgende Empfehlungen zur Auffrischungsimpfung:',
        'bis 20: sofortige Auffrischung         21-40:   in einem Jahr',
        '41-60:  in fünf Jahren                 über 60: in zehn Jahren',
      ],
    },
    {
      testName: 'Hepatitis C PCR',
      resultGroup: 'Molekulare Diagnostik',
      accreditation: null,
      measuredValue: '12038493',
      unit: 'IU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: '*+',
      comment: [
        'Nachweis von Hepatitis C Virus-RNA mittels Nukleinsäure -',
        'Amplifikation (Panther, HOLOGIC).',
        '',
        'Nachweis kann in frühem/akutem oder chronischem Stadium einer Hep.C,',
        'sowie bei asymptomatischen Trägern durchgeführt werden.',
        'Die Anzahl der Hep.-C-RNA Kopien IU/ml reflektiert das Ausmass der',
        'HCV-Virämie zum Zeitpunkt der Blutentnahme.',
        '',
        'Untere Grenze der Quantifizierung: 10 IU/ml.',
        'Linearer Messbereich: 10 IU/ml - 100000000 IU/ml.',
      ],
    },
    {
      testName: 'Corona Virus PCR (SARS-CoV-2)',
      resultGroup: 'Molekulare Diagnostik',
      accreditation: null,
      measuredValue: 'nachweisbar',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '.',
        'SARS-CoV-2-RNA untersucht mittels RT-PCR (Polymerase Kettenreaktion)',
        '(Alinity m, Abbott, SARS-CoV-2 AMP Kit, CE IVD)',
        'Ergebnis gültig zum Zeitpunkt der Probenentnahme.',
        'Ein negativer Test schließt unter Umständen einen',
        'späteren positiven Test nicht aus.',
        'Nachweisgrenze: 100 Viruskopien/ml.',
        '.',
        'RT-PCR based nucleid acid of SARS-CoV2',
        '(Alinity m, Abbott, SARS-CoV-2 AMP Kit, CE IVD).',
        'The result is valid at the time of sampling.',
        'A negative result does not exclude a positive result',
        'on following test.',
        'Limit of Detection: 100 viruscopy/ml.',
        '.',
        'Befunderstellung / Report created by:',
        'IMCL/SYNLAB Wien',
      ],
    },
    {
      testName: 'CT Wert (Cyclus Schwellenwert)',
      resultGroup: 'Molekulare Diagnostik',
      accreditation: null,
      measuredValue: '12.30',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [],
    },
    {
      testName: 'Lithium',
      resultGroup: 'Medikamentenspiegel (aus Serum)',
      accreditation: 'A',
      measuredValue: '0.4',
      unit: 'mmol/l',
      standardRange: '0.6-1.2',
      rangeFrom: '0.6',
      rangeTo: '1.2',
      pathological: '*-',
      comment: [],
    },
    {
      testName: 'FSME IgG Antikörper',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: 'N',
      measuredValue: '> 60',
      unit: 'VE',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Referenzbereiche (VE: Virotech Einheiten):',
        '< 11       sofortige Impfung empfohlen',
        'Ab 11 es kann von einem Impfschutz ausgegangen werden',
        '',
        'Die Dauer des Impfschutzes ist abhängig',
        'sowohl von der Anzahl der bereits erfolgten',
        'Auffrischungen als auch vom Lebensalter.',
      ],
    },
    {
      testName: 'Covid 19 IgG AK',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '3000',
      unit: 'AU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Der Test dient zum Nachweis von Covid-19-IgG-Ak quantitativ',
        'einschl.neutralisierende AK gegen Spike-Rezeptorbindungdomäne (RBD)',
        'der S1-Untereinheit des Spike-Proteins von SARS-CoV-2.',
        'Dadurch sind sowohl Hinweise auf abgelaufene Infektionen als auch',
        'Impferfolge erkennbar!',
      ],
    },
    {
      testName: 'Internat.WHO Einheit Covid-19AK',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '426',
      unit: 'BAU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        'Der Wert "BAU/ml" ist eine Umrechnung mittels eines WHO-Standards,',
        'damit Tests verschiedener Hersteller miteinander vergleichbar sind.',
        'Ab einem Wert von 15 BAU/ml korreliert das Ergebnis mit dem',
        'Neutralisationstest und zeigt das Vorliegen von neutralisiernden',
        'Antikörpern gegen SARS-CoV-2 an.',
      ],
    },
    {
      testName: 'Covid-19 AK Interpretation:',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '',
      unit: null,
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: ['SARS-Cov-2 IgG AK nachweisbar.', 'Hinweis auf stattgehabte Infektion und/oder Impfung.'],
    },
    {
      testName: 'Masern IgG AK',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '20',
      unit: 'AU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '< 13.5        negativ',
        '13.5 - 16.4   grenzwertig',
        'ab 16.5       positiv',
        'Ab einem Wert von 16.5 AU/ml kann von Immunität',
        'bzw. Impfschutz ausgegangen werden.',
      ],
    },
    {
      testName: 'Mumps-IgG-Antikörper',
      resultGroup: 'Virologische Befunde (aus Serum)',
      accreditation: null,
      measuredValue: '20',
      unit: 'AU/ml',
      standardRange: null,
      rangeFrom: null,
      rangeTo: null,
      pathological: null,
      comment: [
        '< 9      negativ',
        '9 - 10   grenzwertig',
        'ab 11    positiv',
        'Ab einem Wert von 9 AU/ml kann von Immunität',
        'bzw. Impfschutz ausgegangen werden.',
      ],
    },
  ],
};
