/* tslint:disable */
/* eslint-disable */
/**
 * Befunde API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from '../configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, type RequestArgs, BaseAPI, RequiredError, operationServerMap } from '../base';
// @ts-ignore
import type { RestError } from '../model';
// @ts-ignore
import type { UpsertUserAccountRequest } from '../model';
// @ts-ignore
import type { UserSearchResponse } from '../model';
/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Unlink user to an account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccount: async (id: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteUserAccount', 'id', id)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary User Search
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} [accountId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers: async (page: number, pageSize: number, accountId?: string, search?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'page' is not null or undefined
            assertParamExists('searchUsers', 'page', page)
            // verify required parameter 'pageSize' is not null or undefined
            assertParamExists('searchUsers', 'pageSize', pageSize)
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['pageSize'] = pageSize;
            }

            if (accountId !== undefined) {
                localVarQueryParameter['accountId'] = accountId;
            }

            if (search !== undefined) {
                localVarQueryParameter['search'] = search;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link user to an account
         * @param {string} id 
         * @param {UpsertUserAccountRequest} upsertUserAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertUserAccount: async (id: string, upsertUserAccountRequest: UpsertUserAccountRequest, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('upsertUserAccount', 'id', id)
            // verify required parameter 'upsertUserAccountRequest' is not null or undefined
            assertParamExists('upsertUserAccount', 'upsertUserAccountRequest', upsertUserAccountRequest)
            const localVarPath = `/user/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication bearerAuth required
            // http bearer authentication required
            await setBearerAuthToObject(localVarHeaderParameter, configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(upsertUserAccountRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Unlink user to an account
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteUserAccount(id: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteUserAccount(id, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.deleteUserAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary User Search
         * @param {number} page 
         * @param {number} pageSize 
         * @param {string} [accountId] 
         * @param {string} [search] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async searchUsers(page: number, pageSize: number, accountId?: string, search?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserSearchResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.searchUsers(page, pageSize, accountId, search, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.searchUsers']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link user to an account
         * @param {string} id 
         * @param {UpsertUserAccountRequest} upsertUserAccountRequest 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async upsertUserAccount(id: string, upsertUserAccountRequest: UpsertUserAccountRequest, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.upsertUserAccount(id, upsertUserAccountRequest, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UserApi.upsertUserAccount']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * 
         * @summary Unlink user to an account
         * @param {UserApiDeleteUserAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteUserAccount(requestParameters: UserApiDeleteUserAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.deleteUserAccount(requestParameters.id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary User Search
         * @param {UserApiSearchUsersRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchUsers(requestParameters: UserApiSearchUsersRequest, options?: RawAxiosRequestConfig): AxiosPromise<UserSearchResponse> {
            return localVarFp.searchUsers(requestParameters.page, requestParameters.pageSize, requestParameters.accountId, requestParameters.search, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link user to an account
         * @param {UserApiUpsertUserAccountRequest} requestParameters Request parameters.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertUserAccount(requestParameters: UserApiUpsertUserAccountRequest, options?: RawAxiosRequestConfig): AxiosPromise<void> {
            return localVarFp.upsertUserAccount(requestParameters.id, requestParameters.upsertUserAccountRequest, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for deleteUserAccount operation in UserApi.
 * @export
 * @interface UserApiDeleteUserAccountRequest
 */
export interface UserApiDeleteUserAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiDeleteUserAccount
     */
    readonly id: string
}

/**
 * Request parameters for searchUsers operation in UserApi.
 * @export
 * @interface UserApiSearchUsersRequest
 */
export interface UserApiSearchUsersRequest {
    /**
     * 
     * @type {number}
     * @memberof UserApiSearchUsers
     */
    readonly page: number

    /**
     * 
     * @type {number}
     * @memberof UserApiSearchUsers
     */
    readonly pageSize: number

    /**
     * 
     * @type {string}
     * @memberof UserApiSearchUsers
     */
    readonly accountId?: string

    /**
     * 
     * @type {string}
     * @memberof UserApiSearchUsers
     */
    readonly search?: string
}

/**
 * Request parameters for upsertUserAccount operation in UserApi.
 * @export
 * @interface UserApiUpsertUserAccountRequest
 */
export interface UserApiUpsertUserAccountRequest {
    /**
     * 
     * @type {string}
     * @memberof UserApiUpsertUserAccount
     */
    readonly id: string

    /**
     * 
     * @type {UpsertUserAccountRequest}
     * @memberof UserApiUpsertUserAccount
     */
    readonly upsertUserAccountRequest: UpsertUserAccountRequest
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * 
     * @summary Unlink user to an account
     * @param {UserApiDeleteUserAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public deleteUserAccount(requestParameters: UserApiDeleteUserAccountRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).deleteUserAccount(requestParameters.id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary User Search
     * @param {UserApiSearchUsersRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public searchUsers(requestParameters: UserApiSearchUsersRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).searchUsers(requestParameters.page, requestParameters.pageSize, requestParameters.accountId, requestParameters.search, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link user to an account
     * @param {UserApiUpsertUserAccountRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public upsertUserAccount(requestParameters: UserApiUpsertUserAccountRequest, options?: RawAxiosRequestConfig) {
        return UserApiFp(this.configuration).upsertUserAccount(requestParameters.id, requestParameters.upsertUserAccountRequest, options).then((request) => request(this.axios, this.basePath));
    }
}

