import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Configuration,
  UserApi,
  UserApiDeleteUserAccountRequest,
  UserApiSearchUsersRequest,
  UserApiUpsertUserAccountRequest,
  UserSearchResponse,
} from '../generated/openapi/befunde-api';
import { loadUser } from '../oidcConfig.ts';

const api = new UserApi(new Configuration({ accessToken: () => loadUser()?.access_token ?? '' }), window._env_.API_URL);

export const useSearchUsers = (params: UserApiSearchUsersRequest) =>
  useQuery<UserSearchResponse, AxiosError>({
    queryKey: [api.searchUsers.name, params],
    queryFn: async () => {
      const res = await api.searchUsers(params);
      return res?.data;
    },
    retry: false,
  });

export const useUpsertUserAccount = () =>
  useMutation<AxiosResponse<void>, AxiosError, UserApiUpsertUserAccountRequest>({
    mutationFn: params => api.upsertUserAccount(params),
  });

export const useDeleteUserAccount = () =>
  useMutation<AxiosResponse<void>, AxiosError, UserApiDeleteUserAccountRequest>({
    mutationFn: params => api.deleteUserAccount(params),
  });
