import React from 'react';
import { ObservationResult } from '../Types';
import { RangeRenderer } from './RangeRenderer';
import { Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { cx } from '@emotion/css';
import { useGridClassNames } from '../OrderResult.styles.ts';

// TODO WIP

export const ObservationResultRenderer: React.FC<{ result: ObservationResult; deemphasizePathological: boolean }> = ({
  result,
  deemphasizePathological,
}) => {
  const {
    gridClassName,
    entryBorderClassName,
    emphasizedClassName,
    accreditationClassName,
    parameterClassName,
    pathologicalClassName,
    unitClassName,
    rangeClassName,
    graphicClassName,
    commentClassName,
  } = useGridClassNames();
  const emphasize = !!result.pathological && !deemphasizePathological;
  const hasComments = result.comment.length > 0;
  return (
    <>
      <div className={cx(gridClassName, hasComments ? '' : entryBorderClassName)}>
        <div className={accreditationClassName}>{result.accreditation}</div>
        <div className={cx(parameterClassName, emphasize ? emphasizedClassName : '')}>
          <Typography.Text ellipsis className={emphasize ? emphasizedClassName : ''}>
            {result.testName}
          </Typography.Text>
          <span>{result.measuredValue}</span>
        </div>
        <div className={cx(pathologicalClassName, emphasize ? emphasizedClassName : '')}>{result.pathological}</div>
        <div className={unitClassName}>{result.unit}</div>
        <div className={rangeClassName}>{result.standardRange}</div>
        <div className={graphicClassName}>
          <RangeRenderer
            result={result}
            width={'200px'}
            height={'12px'}
            deemphasizePathological={deemphasizePathological}
          />
        </div>
      </div>

      {hasComments ? (
        <div className={cx(gridClassName, entryBorderClassName)}>
          <div className={commentClassName}>
            <InfoCircleOutlined />
            <pre>{result.comment.join('\n')}</pre>
          </div>
        </div>
      ) : null}
    </>
  );
};
