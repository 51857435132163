import React, { useEffect, useState } from 'react';
import 'dayjs/locale/de-at';
import '@fontsource/outfit/400.css';
import '@fontsource/outfit/500.css';
import '@fontsource/outfit/600.css';
import { App as AntApp, Button, ConfigProvider, FloatButton, Layout, Result, Spin } from 'antd';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import 'antd/dist/reset.css';
import './styles/global.css';
import { NoAccess } from './components/NoAccess';
import { AppHeader } from './components/AppHeader';
import { SCROLLER } from './utils/elementIds';
import { datadogRum } from '@datadog/browser-rum';
import { ErrorBoundary } from 'react-error-boundary';
import { LoadingIndicator } from './components/LoadingIndicator';
import { Role } from './utils/user';
import { ErrorFallback } from './components/ErrorFallback';
import { OfflineBlockingModal } from './components/OfflineBlockingModal';
import { AppVersionCheckerModal } from './components/AppVersionCheckerModal';
import { AppVersionAutoUpdater } from './components/AppVersionAutoUpdater.tsx';
import { PrivateRoute } from './components/PrivateRoute';
import { css } from '@emotion/css';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { LoadingOutlined, ReloadOutlined } from '@ant-design/icons';
import { useAuth } from 'react-oidc-context';
import { useAuthEvents } from './hooks/useAuthEvents';
import deDE from 'antd/locale/de_DE';
import { UserMenuWrapper } from './UserMenuWrapper.tsx';
import { SocketProvider } from './socket/SocketProvider.tsx';
import { Befunde } from './views/Befunde.tsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { TopMenu } from './components/TopMenu.tsx';
import { Accounts } from './views/admin/Accounts.tsx';
import { Users } from './views/admin/Users.tsx';

const { Content } = Layout;

dayjs.locale('de-at');
// plugins needed for production build
dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

Spin.setDefaultIndicator(<LoadingOutlined />);

const queryClient = new QueryClient();

export const App: React.FC = () => {
  const auth = useAuth();
  const [appInitialized, setAppInitialized] = useState(false);

  useAuthEvents();

  useEffect(() => {
    if (!auth.isAuthenticated || appInitialized) {
      return;
    }

    datadogRum.setUser({
      id: auth.user?.profile.sub,
      name: auth.user?.profile.preferred_username,
      email: auth.user?.profile.email,
    });
    datadogRum.startSessionReplayRecording();

    setAppInitialized(true);
  }, [auth, appInitialized]);

  const styles = {
    layout: css`
      min-height: 100dvh;
    `,
    mainContent: css`
      height: calc(100dvh - 80px);

      .ant-layout-content,
      .ant-layout-sider-children {
        overflow-y: auto;
      }
    `,
  };

  if (auth.isLoading) {
    return (
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: '#2b2b2b',
          },
        }}
      >
        <LoadingIndicator height="100dvh" />
      </ConfigProvider>
    );
  }

  if (!appInitialized && auth.error) {
    return (
      <ConfigProvider
        theme={{
          cssVar: true,
          token: {
            colorPrimary: '#2b2b2b',
          },
        }}
      >
        <Result
          status={500}
          title="Authentifizierung fehlgeschlagen"
          subTitle={auth.error.message}
          extra={
            <Button icon={<ReloadOutlined />} onClick={() => (window.location.href = '/')} type="primary">
              Applikation neu laden
            </Button>
          }
        />
      </ConfigProvider>
    );
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ConfigProvider
        locale={deDE}
        theme={{
          cssVar: true,
          token: {
            colorLink: '#4A88CB',
            colorPrimary: '#2b2b2b',
            fontFamily: 'Outfit, sans-serif',
            fontSize: 16,
            controlItemBgActive: '#fff7dc',
            controlItemBgActiveHover: '#ffeeb4',
          },
          components: {
            Layout: {
              headerBg: '#fff',
              bodyBg: '#fff',
              headerHeight: 80,
            },
            Menu: {
              itemBg: '#F8F7F4',
              subMenuItemBg: '#F8F7F4',
            },
          },
        }}
      >
        <AntApp>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            {!appInitialized && <LoadingIndicator height="100dvh" />}
            {appInitialized && (
              <SocketProvider>
                <BrowserRouter>
                  <Layout className={styles.layout}>
                    <AppHeader userActions={<UserMenuWrapper />} topMenu={<TopMenu />} />
                    <Layout className={styles.mainContent}>
                      <Content id={SCROLLER}>
                        <Routes>
                          <Route element={<PrivateRoute />}>
                            <Route path="/:id?" element={<Befunde />} />
                          </Route>
                          <Route element={<PrivateRoute roles={[Role.ROLE_BEF_MEDCOM]} />}>
                            <Route path="/admin/accounts" element={<Accounts />} />
                            <Route path="/admin/benutzer" element={<Users />} />
                          </Route>
                          <Route path="/no-access" element={<NoAccess />} />
                          <Route path="*" element={<Navigate replace to="/" />} />
                        </Routes>
                      </Content>
                      <FloatButton.BackTop
                        target={() => document.getElementById(SCROLLER) as HTMLElement}
                        visibilityHeight={2500}
                      />
                      <AppVersionCheckerModal />
                      <AppVersionAutoUpdater />
                      <OfflineBlockingModal />
                    </Layout>
                  </Layout>
                </BrowserRouter>
              </SocketProvider>
            )}
          </ErrorBoundary>
        </AntApp>
      </ConfigProvider>
    </QueryClientProvider>
  );
};
