import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAppVersionStore } from '../store/useAppVersionStore.ts';

export const AppVersionAutoUpdater: React.FC = () => {
  const location = useLocation();
  const { bannerVisible } = useAppVersionStore();

  useEffect(() => {
    const shouldUpdate = bannerVisible();
    if (!shouldUpdate) {
      // no update available
      return;
    }

    if (location.state) {
      // it's a location change with state involved, ignore it
      return;
    }

    window.location.reload();
  }, [location, bannerVisible]);

  return null;
};
