import React from 'react';
import { ObservationResult, OrderResult } from '../Types';
import { ObservationResultRenderer } from './ObservationResultRenderer';
import { Filter } from '../filter/FilterTypes';
import { cx } from '@emotion/css';
import { useGridClassNames } from '../OrderResult.styles.ts';

type Group = {
  groupName: string;
  results: ObservationResult[];
};

function matchFilter(result: ObservationResult, filter: Filter) {
  if (filter.pathological !== null && (result.pathological || '').length > 0 !== filter.pathological) {
    return false;
  }
  return true;
}

/**
 * Intentionally no hash grouping used so that the order of the results is preserved,
 * regardless of whether a group can occur more than once
 */
const group = (results: ObservationResult[], filter: Filter): Group[] => {
  const groups = [];

  let lastGroup: Group | null = null;
  for (const result of results) {
    if (matchFilter(result, filter)) {
      if (lastGroup === null || lastGroup!.groupName !== result.resultGroup) {
        lastGroup = { groupName: result.resultGroup || '', results: [result] };
        groups.push(lastGroup);
      } else {
        lastGroup.results.push(result);
      }
    }
  }

  return groups;
};

// TODO WIP
export const ObservationResultsRenderer: React.FC<{
  result: OrderResult;
  filter: Filter;
  deemphasizePathological: boolean;
}> = ({ result, filter, deemphasizePathological }) => {
  const {
    gridClassName,
    headerBorderClassName,
    headerClassName,
    subheaderClassName,
    accreditationClassName,
    parameterClassName,
    pathologicalClassName,
    unitClassName,
    rangeClassName,
    graphicClassName,
  } = useGridClassNames();

  // TODO better state handling
  const groups = group(result.observationResults, filter);

  if (groups.length === 0) {
    // TODO
    return <>no match</>;
  }

  return groups.map((group, index) => {
    return (
      <React.Fragment key={`${group.groupName}${index}`}>
        <div className={gridClassName}>
          <div className={headerClassName}>{group.groupName}</div>
        </div>
        <div className={cx(gridClassName, headerBorderClassName)}>
          <div className={cx(accreditationClassName, subheaderClassName)}></div>
          <div className={cx(parameterClassName, subheaderClassName)}>
            <span>Untersuchung</span>
            <span>Ergebnis</span>
          </div>
          <div className={cx(pathologicalClassName, subheaderClassName)}></div>
          <div className={cx(unitClassName, subheaderClassName)}>Einheit</div>
          <div className={cx(rangeClassName, subheaderClassName)}>Normbereich</div>
          <div className={cx(graphicClassName, subheaderClassName)}></div>
        </div>
        {group.results.map((result, index) => (
          <ObservationResultRenderer
            key={`${result.testName}${index}`}
            result={result}
            deemphasizePathological={deemphasizePathological}
          />
        ))}
      </React.Fragment>
    );
  });
};
