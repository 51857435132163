import React, { useEffect, useState } from 'react';
import { Alert, Button, Modal, Space } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { useAppVersionStore } from '../store/useAppVersionStore.ts';

export const AppVersionCheckerModal: React.FC = () => {
  const [currentRuntimeContent, setCurrentRuntimeContent] = useState('');
  const { newVersionAvailable, setNewVersionAvailable, modalSuppressed, setModalSuppressed } = useAppVersionStore();

  // immediately load the current version on first render
  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('/runtime.js');
        const content = await response.text();
        setCurrentRuntimeContent(content);
      } catch {
        // suppress network errors etc.
      }
    })();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(async () => {
      try {
        const response = await fetch('/runtime.js');
        const content = await response.text();
        setCurrentRuntimeContent(content);
        if (currentRuntimeContent !== '' && currentRuntimeContent !== content) {
          setNewVersionAvailable(true);
        }
      } catch {
        // suppress network errors etc.
      }
    }, 30000);

    if (newVersionAvailable) {
      clearTimeout(intervalId);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [currentRuntimeContent, newVersionAvailable, setNewVersionAvailable]);

  return (
    <Modal
      title="Neue Version von labcomplete verfügbar!"
      open={newVersionAvailable && !modalSuppressed}
      width={600}
      destroyOnClose
      footer={
        <Space direction="horizontal">
          <Button danger onClick={() => setModalSuppressed(true)}>
            Jetzt nicht
          </Button>
          <Button type="primary" icon={<ReloadOutlined />} onClick={() => window.location.reload()}>
            Auf die neueste Version aktualisieren
          </Button>
        </Space>
      }
    >
      <p>Es ist eine neue Version der Anwendung verfügbar, daher muss die aktuelle Ansicht aktualisiert werden.</p>
      <Alert
        type="warning"
        showIcon
        message="Es wird dringend empfohlen diesen Schritt jetzt durchzuführen!"
        description={
          <>
            Es können <strong>unerwartete Fehler</strong> auftreten, wenn Sie die Aktualisierung nicht jetzt
            durchführen. Die <strong>Aktualisierung dauert nur ein bis zwei Sekunden</strong>, dann können Sie Ihre
            Arbeit wie gewohnt fortsetzen.
          </>
        }
      />
      <br />
      Wenn Sie Ihre Eingaben noch zu speichern versuchen wollen, dann klicken Sie auf <i>Jetzt nicht</i>.
    </Modal>
  );
};
