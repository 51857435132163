import React, { useEffect } from 'react';
import { App, Button, Form, Modal, Select, Typography } from 'antd';
import { SaveOutlined } from '@ant-design/icons';
import { useForm } from 'antd/es/form/Form';
import { Account, User } from '../../../generated/openapi/befunde-api';
import { useUpsertUserAccount } from '../../../api/user.queries.ts';
import { buildAuthzInfo } from '../../../utils/helpers.ts';

type FormModel = {
  accountId: string;
};

const { Option } = Select;

export const UpdateUserModal: React.FC<{
  user: User | null;
  onClose: () => void;
  accounts: Account[];
}> = ({ user, onClose, accounts }) => {
  const [form] = useForm<FormModel>();
  const { message } = App.useApp();

  const { mutate: upsert, isPending } = useUpsertUserAccount();

  const save = async (values: FormModel) => {
    upsert(
      {
        id: user!.id,
        upsertUserAccountRequest: values,
      },
      {
        onSuccess: () => {
          message.success('Beziehung wurde gespeichert');
          onClose();
        },
        onError: e => {
          message.error('Beziehung konnte nicht gespeichert werden: ' + e);
        },
      }
    );
  };

  useEffect(() => {
    form.setFieldsValue({
      accountId: user?.accountId,
    });
  }, [form, user]);

  return (
    <Modal
      width={600}
      title="Benutzer/Account Beziehung bearbeiten"
      okText="Speichern"
      okButtonProps={{ icon: <SaveOutlined /> }}
      open={!!user}
      onCancel={onClose}
      onOk={form.submit}
      confirmLoading={isPending}
      destroyOnClose
      forceRender
    >
      <Form<FormModel> layout="vertical" form={form} onFinish={save}>
        <Form.Item name="accountId" label="Account" rules={[{ type: 'string', required: true, whitespace: true }]}>
          <Select
            style={{ width: '100%' }}
            showSearch
            placeholder="Suche Account nach Name, Laborkennung und Identifikator"
            optionFilterProp="search"
            optionLabelProp="taglabel"
          >
            {[...accounts]
              .sort((a, b) => a.name.localeCompare(b.name))
              .map(account => {
                return (
                  <Option
                    key={account.id}
                    value={account.id}
                    taglabel={account.name}
                    search={`${account.name} ${account.authz.map(it => it.labId).join(' ')} ${account.authz.map(it => it.identifiers.join(' ')).join(' ')}`}
                  >
                    <div>{account.name}</div>
                    <Typography.Text type="secondary">{buildAuthzInfo(account.authz)}</Typography.Text>
                  </Option>
                );
              })}
          </Select>
        </Form.Item>
        {/* hack for enter button support */}
        <Button htmlType="submit" hidden />
      </Form>
    </Modal>
  );
};
