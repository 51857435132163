import React, { useEffect, useState } from 'react';
import { Avatar, Divider, Flex, Input, List, Skeleton, theme } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { css, cx } from '@emotion/css';
import { SearchOutlined } from '@ant-design/icons';
import { BEFUNDE_LIST } from '../../utils/elementIds.ts';
import { Link } from 'react-router-dom';

interface DataType {
  gender: string;
  name: {
    title: string;
    first: string;
    last: string;
  };
  email: string;
  picture: {
    large: string;
    medium: string;
    thumbnail: string;
  };
  nat: string;
}

const mockData: { results: DataType[] } = {
  results: [
    {
      gender: 'female',
      name: {
        title: 'Mrs',
        first: 'النا',
        last: 'احمدی',
      },
      email: 'ln.hmdy@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/women/61.jpg',
        medium: 'https://randomuser.me/api/portraits/med/women/61.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/women/61.jpg',
      },
      nat: 'IR',
    },
    {
      gender: 'male',
      name: {
        title: 'Mr',
        first: 'Roberto',
        last: 'León',
      },
      email: 'roberto.leon@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/men/36.jpg',
        medium: 'https://randomuser.me/api/portraits/med/men/36.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/men/36.jpg',
      },
      nat: 'ES',
    },
    {
      gender: 'male',
      name: {
        title: 'Mr',
        first: 'Patrick',
        last: 'Sanchez',
      },
      email: 'patrick.sanchez@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/men/43.jpg',
        medium: 'https://randomuser.me/api/portraits/med/men/43.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/men/43.jpg',
      },
      nat: 'US',
    },
    {
      gender: 'male',
      name: {
        title: 'Mr',
        first: 'Custódio',
        last: 'Gomes',
      },
      email: 'custodio.gomes@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/men/44.jpg',
        medium: 'https://randomuser.me/api/portraits/med/men/44.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/men/44.jpg',
      },
      nat: 'BR',
    },
    {
      gender: 'male',
      name: {
        title: 'Mr',
        first: 'Soham',
        last: 'Evans',
      },
      email: 'soham.evans@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/men/92.jpg',
        medium: 'https://randomuser.me/api/portraits/med/men/92.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/men/92.jpg',
      },
      nat: 'GB',
    },
    {
      gender: 'female',
      name: {
        title: 'Ms',
        first: 'Scarlett',
        last: 'Williams',
      },
      email: 'scarlett.williams@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/women/67.jpg',
        medium: 'https://randomuser.me/api/portraits/med/women/67.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/women/67.jpg',
      },
      nat: 'NZ',
    },
    {
      gender: 'female',
      name: {
        title: 'Mrs',
        first: 'Emily',
        last: 'Flores',
      },
      email: 'emily.flores@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/women/93.jpg',
        medium: 'https://randomuser.me/api/portraits/med/women/93.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/women/93.jpg',
      },
      nat: 'US',
    },
    {
      gender: 'male',
      name: {
        title: 'Mr',
        first: 'Leon',
        last: 'Jäger',
      },
      email: 'leon.jager@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/men/75.jpg',
        medium: 'https://randomuser.me/api/portraits/med/men/75.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/men/75.jpg',
      },
      nat: 'DE',
    },
    {
      gender: 'female',
      name: {
        title: 'Miss',
        first: 'Maëlle',
        last: 'Hubert',
      },
      email: 'maelle.hubert@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/women/37.jpg',
        medium: 'https://randomuser.me/api/portraits/med/women/37.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/women/37.jpg',
      },
      nat: 'FR',
    },
    {
      gender: 'female',
      name: {
        title: 'Miss',
        first: 'Tilde',
        last: 'Sørensen',
      },
      email: 'tilde.sorensen@example.com',
      picture: {
        large: 'https://randomuser.me/api/portraits/women/92.jpg',
        medium: 'https://randomuser.me/api/portraits/med/women/92.jpg',
        thumbnail: 'https://randomuser.me/api/portraits/thumb/women/92.jpg',
      },
      nat: 'DK',
    },
  ],
};

export const BefundeList: React.FC<{ selectedId: string | undefined }> = ({ selectedId }) => {
  const { token } = theme.useToken();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<DataType[]>([]);

  const loadMoreData = () => {
    if (loading) {
      return;
    }
    setLoading(true);

    try {
      setData([...data, ...mockData.results]);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    loadMoreData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Flex
      vertical
      gap={token.paddingLG}
      className={css`
        height: 100%;
        width: 100%;

        @media (min-width: ${token.screenLG}px) {
          width: 350px;
        }

        @media (min-width: ${token.screenXXL}px) {
          width: 400px;
        }
      `}
    >
      <Input size="large" placeholder="Name, SVNR, Auftragsnummer ..." prefix={<SearchOutlined />} />
      <div
        id={BEFUNDE_LIST}
        className={css`
          height: 100%;
          overflow-y: auto;
          width: 100%;
          border-right: 1px solid ${token.colorBorder};
          padding-right: ${token.paddingXS}px;

          @media (max-width: ${token.screenLG}px) {
            border-right: none;
            padding-right: 0;
          }
        `}
      >
        <InfiniteScroll
          dataLength={data.length}
          next={loadMoreData}
          hasMore={data.length < 50}
          loader={<Skeleton avatar paragraph={{ rows: 1 }} active />}
          endMessage={<Divider plain>It is all, nothing more 🤐</Divider>}
          scrollableTarget={BEFUNDE_LIST}
        >
          <List
            dataSource={data}
            renderItem={item => (
              <Link to={`/${item.email}`}>
                <List.Item
                  key={item.email}
                  className={cx(
                    css`
                      border-radius: ${token.borderRadius}px;
                      border-block-end: none !important;
                      padding-left: ${token.paddingXS}px !important;
                      padding-right: ${token.paddingXS}px !important;

                      // :hover {
                      //   background-color: ${token.controlItemBgActive};
                      // }
                    `,
                    {
                      [css`
                        background-color: ${token.controlItemBgActiveHover};
                      `]: item.email === selectedId,
                    }
                  )}
                >
                  <List.Item.Meta
                    avatar={
                      <Avatar>
                        {item.name.first.charAt(0).toUpperCase() + item.name.last.charAt(0).toUpperCase()}
                      </Avatar>
                    }
                    title={item.name.last}
                    description={item.email}
                  />
                </List.Item>
              </Link>
            )}
          />
        </InfiniteScroll>
      </div>
    </Flex>
  );
};
