import { useMutation, useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import {
  Account,
  AccountApi,
  AccountApiCreateAccountRequest,
  AccountApiUpdateAccountRequest,
  AccountListResponse,
  Configuration,
} from '../generated/openapi/befunde-api';
import { loadUser } from '../oidcConfig.ts';

const api = new AccountApi(
  new Configuration({ accessToken: () => loadUser()?.access_token ?? '' }),
  window._env_.API_URL
);

export const useGetAllAccounts = () =>
  useQuery<AccountListResponse, AxiosError>({
    queryKey: [api.getAllAccounts.name],
    queryFn: async () => {
      const res = await api.getAllAccounts();
      return res?.data;
    },
    retry: false,
  });

export const useCreateAccount = () =>
  useMutation<AxiosResponse<Account>, AxiosError, AccountApiCreateAccountRequest>({
    mutationFn: params => api.createAccount(params),
  });

export const useUpdateAccount = () =>
  useMutation<AxiosResponse<Account>, AxiosError, AccountApiUpdateAccountRequest>({
    mutationFn: params => api.updateAccount(params),
  });
